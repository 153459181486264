import { FC, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Link from "next/link";
import useAxios, { client } from "../../../utils/axiosBuyer";
import { setCookie } from "cookies-next";
import { Validate } from "../../../helpers/FormValidation";
import ModalAlert from "../../../helpers/ModalAlert";
import Logo from "../../../components/commons/logo/Logo";
import Input from "../../../components/commons/input/Input";
import Button from "../../../components/commons/button/Button";
import SocialMedia from "../../../components/plugins/SocialMedia";
import { useRouter } from "next/router";

type Props = {};

const SignIn: FC<Props> = ({}) => {
  const axios = useAxios();
  const router = useRouter();

  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [main, setMain] = useState("");
  const [query, setQuery] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) =>
      Validate(values, {
        email: "required|email|max:250",
        password: "required|min:5|max:250",
      }),
    onSubmit: async (value) => {
      formik.setSubmitting(true);
      setLoading(true);

      try {
        const { data } = await axios.post("/buyer/sign-in", value);
        if (data) {
          setCookie("__AUTH_TOKEN", data);
          await client.get(`/buyer/setting/profile`).then((response) => {
            localStorage.setItem("b/profile", JSON.stringify(response.data));
            localStorage.removeItem("b/tour");
            setCookie("b/profile/set", true, {
              maxAge: 600,
            });
            setCookie("is_new_buyer", response.data.is_new_buyer);
            const { forward } = router.query;
            if (forward) {
              window.location.href = `${main}${query ? `?${query}` : ""}`;
            } else {
              window.location.href = "/b";
            }
          });
        }
      } catch (error: any) {
        ModalAlert({
          title: "Oops!!!",
          message: error?.response?.data.message,
          icon: "error",
        });
      } finally {
        setLoading(false);
      }

      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    const { forward } = router.query as any;
    if (forward) {
      const split = forward.split("?");
      setMain(split[0]);
      if (split.length > 1) {
        const search = decodeURIComponent(split[1]);
        setQuery(search);

        const reformat = JSON.parse(
          '{"' +
            search
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        if (reformat.lat && reformat.lng) {
          setCookie("marketing", JSON.stringify(reformat));
        }
      }
    }
  }, [router]);

  return (
    <>
      <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
        <div className="flex flex-col">
          <Link href="/b">
            <a>
              <Logo />
            </a>
          </Link>

          <div className="mt-[1.125rem]">
            <h2 className="text-title2 font-bold text-neutral-900">Sign In</h2>
            <p className="mt-3 font-regular text-small-tight text-neutral-600">
              {"Let’s find storage auctions in your area"}
            </p>
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-7 grid grid-cols-1 gap-y-4 sm:grid-cols-2"
        >
          <div className="col-span-full">
            <Input
              innerRef={emailRef}
              value={formik.values.email}
              name="email"
              type="email"
              label="Email Address"
              onChange={formik.handleChange}
              isInvalid={formik.touched.email}
              invalidMessage={formik.errors.email}
            />
          </div>
          <div className="col-span-full">
            <Input
              innerRef={passRef}
              value={formik.values.password}
              name="password"
              type="password"
              label="Password"
              onChange={formik.handleChange}
              isInvalid={formik.touched.password}
              invalidMessage={formik.errors.password}
            />
          </div>
          <div className="col-span-full flex justify-between items-center">
            <div className="text-small-normal text-neutral-600">
              Lost your password?
            </div>
            <Link href="/b/account-recovery">
              <a className="font-bold text-primary-500 hover:text-primary-600 text-small-normal">
                Recover Now
              </a>
            </Link>
          </div>
          <div className="col-span-full">
            <Button
              type="submit"
              size="lg"
              className="w-full"
              disabled={(!formik.isValid && !!formik.submitCount) || loading}
              isLoading={loading}
            >
              Sign In
            </Button>
          </div>

          <div className="col-span-full my-6">
            <div className="relative flex items-center">
              <div className="flex-grow border-t border-neutral-200"></div>
              <span className="flex-shrink mx-3.5 text-neutral-600 text-tiny-normal">
                Or Sign In With
              </span>
              <div className="flex-grow border-t border-neutral-200"></div>
            </div>
          </div>
          <div className="col-span-full flex flex-col md:flex-row items-center justify-between space-y-5 md:space-x-2.5 md:space-y-0">
            <SocialMedia endpoint="/buyer/oauth" redirect="/b" />
          </div>
          <div className="col-span-full flex justify-center space-x-3 w-full">
            <div className="text-neutral-600 text-small-normal">
              Don’t have account yet?
            </div>
            <Link href="/b/sign-up">
              <a className="font-bold text-primary-500 hover:text-primary-600 text-small-normal">
                Register Now
              </a>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignIn;
